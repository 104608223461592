import React, { useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData } from "constants/apis"
import Notification from "components/Common/Notification"
import Loader from "components/Common/Loader"

const CreateUser = props => {
  const [state, setState] = useState({
    loadingProgress: 10,
    isLoading: false,
    alertMessage: "",
    messageType: "",
  })
  const {
    name,
    email,
    password,
    company_limit,
    team_limit,
    loadingProgress,
    isLoading,
    alertMessage,
    messageType,
    plan_start,
    plan_end,
  } = state
  const updateState = data => setState(prv => ({ ...prv, ...data }))

  const __handleSubmit = () => {
    if (!name) {
      return updateState({
        alertMessage: "Name is required",
        messageType: "danger",
      })
    }
    if (!email) {
      return updateState({
        alertMessage: "Email is required",
        messageType: "danger",
      })
    }
    if (!password) {
      return updateState({
        alertMessage: "Password is required",
        messageType: "danger",
      })
    }
    if (!company_limit || company_limit == 0) {
      return updateState({
        alertMessage: "Company create limit is required",
        messageType: "danger",
      })
    }
    if (!team_limit || team_limit == 0) {
      return updateState({
        alertMessage: "Team create limit is required",
        messageType: "danger",
      })
    }

    if (!plan_start) {
      return updateState({
        alertMessage: "Plan Start is required",
        messageType: "danger",
      })
    }
    if (!plan_end) {
      return updateState({
        alertMessage: "Plan End is required",
        messageType: "danger",
      })
    }

    updateState({ isLoading: true, loadingProgress: 10, alertMessage: "" })
    setTimeout(() => {
      updateState({ loadingProgress: 40 })
    }, 100)
    __postApiData(`/c_owner_user`, {
      name,
      email,
      password,
      company_limit,
      team_limit,
      plan_start,
      plan_end,
    })
      .then(res => {
        console.log(res)
        updateState({ loadingProgress: 80 })

        if (res.response.response_code === "200") {
          updateState({
            alertMessage: res.response.response_message,
            messageType: "success",
          })
        } else {
          updateState({
            alertMessage: res.response.response_message,
            messageType: "danger",
          })
        }

        updateState({ loadingProgress: 100 })
        setTimeout(() => {
          updateState({ isLoading: false })
        }, 1000)
      })
      .catch(error => {
        console.error(error)
        updateState({ loadingProgress: 100 })
        setTimeout(() => {
          updateState({
            isLoading: false,
            alertMessage: "Something went wrong",
            messageType: "danger",
          })
        }, 1000)
      })
  }

  return (
    <React.Fragment>
      {alertMessage && (
        <Notification
          mesage={alertMessage}
          type={messageType}
          closeButton={updateState}
        />
      )}
      {isLoading && <Loader title={""} progress={loadingProgress} />}
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <Card className="rounded-4 p-3" style={{ minHeight: "70vh" }}>
                <CardBody>
                  <CardTitle className="mb-4 border-bottom border-1 pb-3">
                    Client Details
                  </CardTitle>
                  <Row className="mb-4 ">
                    <Col md={"4"} className="">
                      <div className="mb-3">
                        <Label className="form-label"> Name</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder=" "
                          className=" border-0 bg-light rounded-0 p-3"
                          value={name}
                          onChange={e => {
                            updateState({ name: e.target.value })
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={"4"} className="">
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder=" "
                          className=" border-0 bg-light rounded-0 p-3"
                          value={email}
                          onChange={e => {
                            updateState({ email: e.target.value })
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={"4"} className="">
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder=" "
                          className=" border-0 bg-light rounded-0 p-3"
                          value={password}
                          onChange={e => {
                            updateState({ password: e.target.value })
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={"4"} className="">
                      <div className="mb-3">
                        <Label className="form-label">
                          No. of compaines to Create?
                        </Label>
                        <Input
                          name="name"
                          type="number"
                          placeholder=" "
                          className=" border-0 bg-light rounded-0 p-3"
                          value={company_limit}
                          onChange={e => {
                            updateState({ company_limit: e.target.value })
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={"4"} className="">
                      <div className="mb-3">
                        <Label className="form-label">
                          No. of Team to Create?
                        </Label>
                        <Input
                          name="name"
                          type="number"
                          placeholder=" "
                          className=" border-0 bg-light rounded-0 p-3"
                          value={team_limit}
                          onChange={e => {
                            updateState({ team_limit: e.target.value })
                          }}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-4 ">
                    <Col md={"4"} className="">
                      <div className="mb-3">
                        <Label className="form-label"> Plan Start Date</Label>
                        <Input
                          name="name"
                          type="date"
                          placeholder=" "
                          className=" border-0 bg-light rounded-0 p-3"
                          value={plan_start}
                          onChange={e => {
                            updateState({ plan_start: e.target.value })
                          }}
                          min={new Date()}
                        />
                      </div>
                    </Col>
                    <Col md={"4"} className="">
                      <div className="mb-3">
                        <Label className="form-label"> Plan End Date</Label>
                        <Input
                          name="name"
                          type="date"
                          placeholder=" "
                          className=" border-0 bg-light rounded-0 p-3"
                          value={plan_end}
                          onChange={e => {
                            updateState({ plan_end: e.target.value })
                          }}
                          min={plan_start}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <Button
                      onClick={__handleSubmit}
                      className="btn bg-info border-0 float-end mt-3 bg-gradient"
                    >
                      Create User
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(CreateUser)
