import React, { useContext, useEffect, useMemo, useState } from "react"
import { Card, CardBody, Container } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData } from "constants/apis"
import TableContainer from "components/Common/TableContainer"
import { __formatDate } from "constants/constantfun"

const ClientTeams = props => {
  const urlParams = new URLSearchParams(window.location.search)
  const [state, setState] = useState({
    leads: [],
    isLoading: false,
    isEdit: false,
    edit: null,
  })
  const { leads, isLoading } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const columns = useMemo(
    () => [
      {
        Header: <div className="text-center">#</div>,
        filterable: false,
        disableFilters: true,
        accessor: "sr_no",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },

      {
        Header: "Company Name",
        accessor: "company_name",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },

      {
        Header: "Client Name",
        accessor: "owner_name",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Team Name",
        accessor: "name",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Role",
        accessor: "role",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },

      {
        Header: <div className="text-center"> Email</div>,
        accessor: "email",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
      {
        Header: <div className="text-center"> Phone</div>,
        accessor: "phone",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },

      {
        Header: <div className="text-center"> Status</div>,
        accessor: "isActive",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">
              {cellProps.value ? (
                <span className="bg-success badge bg-secondary">Active</span>
              ) : (
                <span className="bg-danger badge bg-secondary">Inactive</span>
              )}
            </div>
          )
        },
      },

      {
        Header: <div className="text-center">Creation Date</div>,
        accessor: "createdAt",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">{__formatDate(cellProps.value)}</div>
          )
        },
      },
    ],
    []
  )
  const __handleGetUsers = search => {
    __postApiData(`/g_owner_team`, {
      _id: urlParams.get("c") || null,
      admin: urlParams.get("id") || null,
    })
      .then(res => {
        if (res.response.response_code === "200") {
          return updateState({
            leads: res.data
              .map((item, i) => ({ ...item, sr_no: i + 1 }))
              .reverse(),
            isLoading: false,
            isAsyncLoading: false,
          })
        }
        return updateState({
          leads: [],
          isLoading: false,
          isAsyncLoading: false,
        })
      })
      .catch(error => {
        console.error(error)
        return updateState({
          leads: [],
          isLoading: false,
          isAsyncLoading: false,
        })
      })
  }

  useEffect(() => {
    updateState({ leads: [], isLoading: true })
    __handleGetUsers("")
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card className=" rounded-4  py-3">
            <CardBody>
              <TableContainer
                columns={columns}
                data={leads}
                customPageSize={30}
                classNameHeader="dark"
                isGlobalFilter
              />
              {leads?.length == 0 && !isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-search"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div className="text-center mt-3">No contact Found</div>
                </div>
              )}
              {isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-loader bx-spin"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ClientTeams)
