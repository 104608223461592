import React, { useContext } from "react"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import FileOverview from "./FileOverview"
import dataContext from "context/data/dataContext"
import { Link } from "react-router-dom"

const AnaliseBox = ({}) => {
  return (
    <>
      <Col md="9" className="">
        <Row>
          <LeadAnalysis />
        </Row>
        <Row>{/* <FileOverview /> */}</Row>
      </Col>

      <PathBox />
    </>
  )
}

const PathBox = params => {
  return (
    <Col md="3">
      <Row className="">
        <Col md="12">
          <Link to={"/create-user"}>
            <Card
              className="mb-3   rounded-4 p-3 d-flex flex-row align-items-center"
              style={{ cursor: "pointer" }}
            >
              <div className="avatar-sm">
                <span className="avatar-title rounded-circle bg-warning font-size-20">
                  <i className="bx bx-user text-white"></i>
                </span>
              </div>
              <h5 className=" mb-0 ms-2  me-auto">Add New Client</h5>
              <i className="bx bx-chevron-right font-size-24"></i>
            </Card>
          </Link>
        </Col>
        <Col md="12">
          <Link to={"/client-list"}>
            <Card
              className="mb-3   rounded-4 p-3 d-flex flex-row align-items-center"
              style={{ cursor: "pointer" }}
            >
              <div className="avatar-sm">
                <span className="avatar-title rounded-circle bg-info font-size-20">
                  <i className="fas fa-user-friends text-white"></i>
                </span>
              </div>
              <h5 className=" mb-0 ms-2  me-auto">Manage Clients</h5>
              <i className="bx bx-chevron-right font-size-24"></i>
            </Card>
          </Link>
        </Col>
      </Row>
    </Col>
  )
}

const LeadAnalysis = () => {
  const { leadOverView } = useContext(dataContext)
  const {
    isLoaded,
    total_client,
    total_company,
    total_team,
    total_expired_plan,
  } = leadOverView
  return (
    <Col md="12">
      <Card className="rounded-4 py-0">
        <CardBody>
          <Row className="mt-3">
            <Col md="3">
              <Card className="  bg-secondary-subtle bg-gradient  rounded-4 p-4 py-3">
                {!isLoaded ? (
                  <h2 className=" text-secondary">
                    <i
                      className="bx bx-loader bx-spin mt-1"
                      style={{ fontSize: "22px" }}
                    ></i>
                  </h2>
                ) : (
                  <h2 className=" text-black">{total_client}</h2>
                )}
                <h6 className="text-secondary ">Client</h6>
              </Card>
            </Col>
            <Col md="3">
              <Card className="  bg-warning-subtle bg-gradient rounded-4 p-4 py-3">
                {!isLoaded ? (
                  <h2 className=" text-warning">
                    <i
                      className="bx bx-loader bx-spin mt-1"
                      style={{ fontSize: "22px" }}
                    ></i>
                  </h2>
                ) : (
                  <h2 className=" text-black">{total_company}</h2>
                )}
                <h6 className="text-warning ">Company</h6>
              </Card>
            </Col>
            <Col md="3">
              <Card className="  bg-info-subtle bg-gradient  rounded-4 p-4 py-3">
                {!isLoaded ? (
                  <h2 className=" text-info">
                    <i
                      className="bx bx-loader bx-spin mt-1"
                      style={{ fontSize: "22px" }}
                    ></i>
                  </h2>
                ) : (
                  <h2 className=" text-black">{total_team}</h2>
                )}
                <h6 className="text-info ">Team</h6>
              </Card>
            </Col>
            <Col md="3">
              <Card className="  bg-danger-subtle bg-gradient  rounded-4 p-4 py-3">
                {!isLoaded ? (
                  <h2 className=" text-danger">
                    <i
                      className="bx bx-loader bx-spin mt-1"
                      style={{ fontSize: "22px" }}
                    ></i>
                  </h2>
                ) : (
                  <h2 className=" text-black">{total_expired_plan}</h2>
                )}
                <h6 className="text-danger ">Plan Expired</h6>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  )
}

export default AnaliseBox
