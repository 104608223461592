import React from "react"
import { Navigate } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPassword from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import CreateUser from "pages/CreateUser"
import ClientList from "pages/ClientList"
import ClientTeams from "pages/ClientTeams"
import CompanyList from "pages/CompanyList"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/create-user", component: <CreateUser /> },
  { path: "/client-list", component: <ClientList /> },
  { path: "/client-teams", component: <ClientTeams /> },
  { path: "/client-companies", component: <CompanyList /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPassword /> },
]

export { authProtectedRoutes, publicRoutes }
