import React, { useContext, useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  NavItem,
  NavLink,
  Table,
  Input,
  Label,
  Col,
  CardTitle,
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData } from "constants/apis"
import dataContext from "context/data/dataContext"
import AnaliseBox from "./comp/AnaliseBox"
import SimpleBar from "simplebar-react"
import { Link } from "react-router-dom"
import { __formatDate } from "constants/constantfun"
const Dashboard = props => {
  const {
    leadOverView,
    setLeadOverView,
    callOverView,
    setCallOverView,
    sourceFileOverView,
    setSourceFileOverView,
  } = useContext(dataContext)

  const __handleGetCallOverViewData = () => {
    __postApiData(`/client_plan_expire`)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setCallOverView({ list: res.data, isLoaded: true })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  const __handleGetLeadOverviewData = () => {
    __postApiData(`/client_overview`)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setLeadOverView({ ...res.data, isLoaded: true })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  const __handleGetSouceFileOverviewData = () => {
    __postApiData(`/api/v1/admin/g_lead_file_overview`)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setSourceFileOverView({ list: res.data, isLoaded: true })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    !leadOverView?.isLoaded && __handleGetLeadOverviewData()
    !callOverView?.isLoaded && __handleGetCallOverViewData()
    //   !sourceFileOverView?.isLoaded && __handleGetSouceFileOverviewData()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <AnaliseBox />
          </Row>

          <Row>
            <Col xl="9">
              <Card className="rounded-4 py-3">
                <CardBody>
                  <CardTitle className=" ">Plan Expired</CardTitle>
                  <div className="mt-4">
                    <SimpleBar style={{ maxHeight: "250px" }}>
                      {!callOverView?.isLoaded && (
                        <h2 className=" text-dark text-center m-5 p-4">
                          <i
                            className="bx bx-loader bx-spin mt-1"
                            style={{ fontSize: "22px" }}
                          ></i>
                        </h2>
                      )}
                      {callOverView?.list?.length > 0 ? (
                        <div className="table-responsive">
                          <Table className="table table-nowrap align-middle table-hover mb-0">
                            <tbody>
                              {callOverView?.list?.map((item, index) => {
                                return (
                                  <tr key={index + 1}>
                                    <td style={{ width: "50px" }}>
                                      <div className="form-check">
                                        {item?.sr_no}
                                      </div>
                                    </td>
                                    <td>
                                      <h5 className="text-truncate font-size-14 mb-1">
                                        {item?.name}
                                      </h5>
                                    </td>
                                    <td>
                                      <p className="text-muted mb-0">
                                        {item?.email}
                                      </p>
                                    </td>
                                    <td>
                                      <p className="text-muted mb-0">
                                        {__formatDate(item?.plan_end)}
                                      </p>
                                    </td>
                                    <td>
                                      <ul className="list-unstyled hstack gap-1 mb-0 justify-content-center">
                                        <li>
                                          <span
                                            className={`btn btn-sm btn-soft-${item.color}`}
                                          >
                                            {item?.expire_status}
                                          </span>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </div>
                      ) : (
                        <>
                          {callOverView?.isLoaded && (
                            <div className="p-5">
                              <div className="text-center ">
                                <i
                                  className="bx bx-search"
                                  style={{ fontSize: "30px" }}
                                ></i>
                              </div>
                              <div className="text-center mt-3">
                                No Data Found
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
